'use client'; // Error components must be Client Components

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import ErrorPage from '@/components/error/error-page';

export const metadata = {
  title: 'Unexpected error',
};

export default function GlobalError({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error);
    console.error(error);
  }, [error]);

  return (
    <ErrorPage
      title="Unexpected error"
      subtitle={`We are working on fixing the problem. Please try again.`}
      buttonLabel="Try again"
      buttonProps={{
        // Attempt to recover by trying to re-render the segment
        onClick: () => reset(),
      }}
    />
  );
}
